@import '~antd/dist/antd.less';

aside .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-layout-sider-zero-width-trigger {
  top: 13px;
  right:  -40px;
}
h3.ant-typography {
  margin-bottom: 0;
}
.login-view {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(16,161,154);
  background: linear-gradient(90deg, rgba(16,161,154,1) 0%, rgba(16,161,114,1) 100%);
}

